import React from 'react';
import * as footerStyles from './footer.module.scss';
import { useTranslation } from 'react-i18next';
import cv from '../../assets/CV_Naama.pdf';

export default function Footer(): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className={footerStyles.container}>
      <div className={footerStyles.stayInTouch}>{t('FOOTER.STAY_IN_TOUCH')}</div>
      <a className={footerStyles.text} href="mailto:naamabm7@gmail.com">
        {t('FOOTER.EMAIL')}
      </a>
      <a
        className={footerStyles.text}
        href="https://www.linkedin.com/in/naama-bassan-61ab14a6/"
        target="_blank"
        rel="noreferrer"
      >
        {t('FOOTER.LINKEDIN')}
      </a>
      <a className={footerStyles.text} href={cv} target="_blank" rel="noreferrer">
        {t('FOOTER.RESUME')}
      </a>
    </div>
  );
}
