import React from 'react';
import { Link } from 'gatsby';
import * as headerStyles from './header.module.scss';

export default function Header(): JSX.Element {
  const [checked, setChecked] = React.useState(false);
  if (typeof window !== 'undefined' && window.matchMedia('(max-width: 48em)').matches) {
    const input = document.getElementById('menu-header');
    if (input !== null) {
      if (checked) {
        input.style.backgroundColor = 'white';
      } else {
        input.style.backgroundColor = 'transparent';
      }
    }
  }
  return (
    <div className={headerStyles.header} id="menu-header">
      <input
        className={headerStyles.menuBtn}
        type="checkbox"
        id="menu-btn"
        defaultChecked={checked}
        onChange={() => setChecked(!checked)}
      />
      <label className={headerStyles.menuIcon} htmlFor="menu-btn">
        <span className={headerStyles.navicon} />
      </label>
      <ul className={headerStyles.menu}>
        <li>
          <Link to="/">Work </Link>
        </li>
        <li>
          <a href="/about">About</a>
        </li>
      </ul>
    </div>
  );
}
