import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from './footer/footer';
import Header from './header/header';

export default function Layout({ children }: any): JSX.Element {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Naama Bassan</title>
        <link rel="canonical" href="https://naamab.com" />
      </Helmet>
      <Header />
      {children}
      <Footer />
    </div>
  );
}
