import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Load translation files
import en from './locales/en.json';

// Define the fallback language
// const fallbackLanguage = {languageTag: 'fr', isRTL: false};
// Check what the best supported langauge is based off of the device languages
// const defaultLanguage =
//   RNLocalize.findBestAvailableLanguage(['fr', 'en']) || fallbackLanguage;

// Initialize the internationalization library
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: en },
    },
    lng: 'en',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
